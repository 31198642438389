import React, { useState, useEffect, useMemo } from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import { graphql, useStaticQuery } from "gatsby"
import { JobTagList } from "../../components/jobTagList/jobTagList"
import { JobOfferCard } from "../../components/jobOfferCard/jobOfferCard"
import "./careersJobs.scss"

const TAG_ALL_JOBS = "TAG_ALL_JOBS"

export const CareersJobs = () => {
    const intl = useIntl()
    const data = useStaticQuery(graphql`
        {
            allMarkdownRemark(
                filter: { frontmatter: { type: { ne: "post" } } }
            ) {
                edges {
                    node {
                        frontmatter {
                            tags
                            title
                            slug
                            location
                            language
                            image {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 300
                                        placeholder: NONE
                                        layout: CONSTRAINED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                        id
                    }
                }
            }
        }
    `)
    const [allJobs, allTags] = useMemo(() => {
        const _allJobs = data.allMarkdownRemark.edges.map((edge) => {
            const { image, ...frontmatter } = edge.node.frontmatter
            return {
                id: edge.node.id,
                ...frontmatter,
                image: image.childImageSharp.gatsbyImageData,
            }
        })

        const _allTags = _allJobs.reduce(
            (acum, job) =>
                acum.concat(
                    job.tags.filter((tag) => !acum.some((item) => item === tag))
                ),
            []
        )
        _allTags.unshift(TAG_ALL_JOBS)

        return [_allJobs, _allTags]
    }, [data])
    const [selectedTag, setSelectedTag] = useState(TAG_ALL_JOBS)
    const [filteredJobs, setFilteredJobs] = useState([])

    useEffect(() => {
        let newFilteredJobs = allJobs.filter(
            ({ language }) => language === intl.locale
        )
        if (selectedTag !== TAG_ALL_JOBS) {
            newFilteredJobs = newFilteredJobs.filter(({ tags }) =>
                tags.some((tag) => tag === selectedTag)
            )
        }

        newFilteredJobs = newFilteredJobs.map(({ id }) => id)
        setFilteredJobs(newFilteredJobs)
    }, [selectedTag, allJobs])

    return (
        <section
            className="carrerJobs"
            style={{ height: allJobs.length * 120 }}
        >
            <span className="introTitle">
                {intl.formatMessage({ id: "CARRER_JOBS_SLOGAN" })}
            </span>
            <h2>{intl.formatMessage({ id: "CARRER_JOBS_TITLE" })}</h2>
            <JobTagList
                tags={allTags}
                onSelectedTag={(tag) => setSelectedTag(tag)}
                type="default"
                className="tagsSelector"
                initialTag={TAG_ALL_JOBS}
            />
            <ul className="jobs">
                {allJobs.map(({ image, title, id, tags, slug, location }) => {
                    return (
                        <li
                            key={id}
                            className={
                                filteredJobs.some((jobId) => id === jobId)
                                    ? undefined
                                    : "hidden"
                            }
                        >
                            <JobOfferCard
                                tags={tags}
                                image={image}
                                label={title}
                                slug={slug}
                                location={location}
                            />
                        </li>
                    )
                })}
            </ul>
        </section>
    )
}
