import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import "./careersIntro.scss"

export const CareersIntro = () => {
    const intl = useIntl()
    const data = useStaticQuery(graphql`
        fragment carrerImage on File {
            childImageSharp {
                gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                )
            }
        }
        query {
            carrer: file(relativePath: { eq: "careersImg2.png" }) {
                ...carrerImage
            }
        }
    `)

    return (
        <div className="careersIntro">
            <div className={"contained"}>
                <div>
                    <span className="introTitle">
                        {intl.formatMessage({
                            id: "CAREERS_SUBTITLE_SLOGAN",
                        })}
                    </span>
                    <h2>{intl.formatMessage({ id: "CAREERS_SUBTITLE" })}</h2>
                    <p>{intl.formatMessage({ id: "CAREERS_INTO_TEXT" })}</p>
                </div>
                <div>
                    <GatsbyImage
                        alt={"colba-logo"}
                        image={data["carrer"].childImageSharp.gatsbyImageData}
                    />
                </div>
            </div>
        </div>
    )
}
