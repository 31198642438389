import React from "react"
import { JobTagList } from "../jobTagList/jobTagList"
import { useIntl, Link } from "gatsby-plugin-react-intl"
import { GatsbyImage } from "gatsby-plugin-image"
import { ColbaButton } from "../colbaButton/colbaButton"
import "./jobOfferCard.scss"

export const JobOfferCard = ({ tags, label, image, slug, location }) => {
    const intl = useIntl()
    return (
        <div className="jobOfferCard">
            {!!image && (
                <GatsbyImage
                    image={{ ...image, aspectRatio: 1 }}
                    className="cardImage"
                    alt=""
                />
            )}
            <div className="cardBody">
                <h3>{label}</h3>
                <span>
                    Colba <span>{` ${location}`}</span>
                </span>
                <div className="cardBottom">
                    <JobTagList
                        tags={tags}
                        className="tagList"
                        type="dissabled"
                    />
                    <Link to={`/careers/${slug}/`}>
                        <ColbaButton
                            label={intl.formatMessage({ id: "LABEL_SEE_MORE" })}
                        />
                    </Link>
                </div>
            </div>
        </div>
    )
}
