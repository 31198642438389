import React, { useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import "./jobTagList.scss"

export const JobTagList = ({
    tags,
    type,
    onSelectedTag,
    initialTag,
    className,
}) => {
    const intl = useIntl()
    const [selectedTag, setselectedTag] = useState(initialTag || "")

    useEffect(() => {
        if (onSelectedTag) {
            onSelectedTag(selectedTag)
        }
    }, [selectedTag])
    return (
        <ul className={`jobTagList ${className || ""}`}>
            {tags.map((tag) => (
                <li
                    key={tag}
                    className={`${type} ${
                        selectedTag === tag && type !== "dissabled"
                            ? "selected"
                            : ""
                    }`}
                    onClick={() => setselectedTag(tag)}
                >
                    {intl.formatMessage({ id: tag })}
                </li>
            ))}
        </ul>
    )
}
