import * as React from "react"
import { MainLayout } from "../components/mainLayout/mainLayout"
import { SEO } from "../components/seo/seo"

import "./index.scss"
import { CareersHeader } from "../blocs/careersHeader/careersHeader"
import { CareersIntro } from "../blocs/careersIntro/careersIntro"
import { useIntl } from "gatsby-plugin-react-intl"
import { CareersJobs } from "../blocs/careersJobs/careersJobs"
const CareersPage = () => {
    const intl = useIntl()
    return (
        <MainLayout>
            <SEO title="Careers" />
            <CareersHeader
                title={intl.formatMessage({ id: "CAREERS_TITLE" })}
                subtitle={intl.formatMessage({ id: "CAREERS_SLOGAN" })}
            />
            <CareersIntro />
            <CareersJobs />
        </MainLayout>
    )
}

export default CareersPage
